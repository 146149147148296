<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content class="indexLayoutContent" style="padding: 0px">
          <div class="page flex-col aipage">
            <div :class="`bardiv ${barclass}`">
              <div class="barlist">
                <template v-for="(item, index) in blinks">
                  <a class="bla" :key="index">
                    <div
                      class="blitem"
                      @click="
                        () => {
                          goDiv(item.class, item.url);
                        }
                      "
                    >
                      <span>{{ item.title }}</span>

                      <div class="lined">
                        <img
                          class="line"
                          referrerpolicy="no-referrer"
                          src="../assets/imgs/line.png"
                        />
                      </div>
                    </div>
                  </a>
                </template>

                <div
                  class="blitem justify-between"
                  style="flex-direction: row"
                  @click="
                    () => {
                      goDiv(item.class, item.url);
                    }
                  "
                >
                  <span
                    v-if="lag == 'en'"
                    @click="
                      () => {
                        changelag('');
                      }
                    "
                    >中</span
                  >
                  <span v-else style="color: rgba(231, 55, 74, 1)">中</span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span v-if="lag == 'en'" style="color: rgba(231, 55, 74, 1)"
                    >EN</span
                  >
                  <span
                    v-else
                    @click="
                      () => {
                        changelag('en');
                      }
                    "
                    >EN</span
                  >
                </div>
              </div>
              <div class="image-wrapper_1 flex-col justify-between w-logo">
                <img
                  class="w-logo-img"
                  referrerpolicy="no-referrer"
                  src="../assets/imgs/logo-2.png"
                />
              </div>

              <div class="closebar" @click="closeBardiv">
                <img src="../assets/imgs/icon-close2.png" />
              </div>

              <div class="bltouch" @click="closeBardiv"></div>
            </div>

            <div class="group_1 flex-col">
              <div class="group_3 flex-row" v-if="barclass == 'closethis'">
                <div class="toplan">
                  <div
                    class="image-wrapper_1 flex-col justify-between w-logo wlailogo"
                  >
                    <img
                      class="w-logo-img"
                      referrerpolicy="no-referrer"
                      src="../assets/imgs/logo-2.png"
                    />
                  </div>

                  <div class="blinks justify-end">
                    <template v-for="(item, index) in blinks">
                      <template v-if="index < blinks.length - 1">
                        <span
                          @mouseover="
                            () => {
                              blinkshandleHover(index);
                            }
                          "
                          @touchstart="
                            () => {
                              blinkshandleHover(index);
                            }
                          "
                          v-if="bselectindex != index"
                          class="item flex-col justify-between"
                          :style="index == 5 ? `width:84px` : ``"
                          :key="index"
                        >
                          <span class="itemtext">{{ item.title }}</span>
                        </span>
                        <span
                          @mouseleave="blinkshandleLeave"
                          @click="
                            () => {
                              goDiv(item.class, item.url);
                            }
                          "
                          v-if="bselectindex == index"
                          class="itemf flex-col justify-between"
                          :style="index == 5 ? `width:84px` : ``"
                          :key="index"
                        >
                          <span class="itemtext">{{ item.title }}</span>

                          <div
                            class="lined"
                            :style="index == 5 ? `width:84px` : ``"
                          >
                            <img
                              class="line"
                              referrerpolicy="no-referrer"
                              src="../assets/imgs/line.png"
                            />
                          </div>
                        </span>
                      </template>
                    </template>

                    <span
                      @click="
                        () => {
                          goDiv(
                            blinks[blinks.length - 1].class,
                            blinks[blinks.length - 1].url
                          );
                        }
                      "
                      :class="`${
                        topbarOpacity >= 1 ? 'itemfsf' : 'itemfs'
                      } flex-col justify-between`"

                      v-if="blinks[blinks.length - 1].title!=''"
                    >
                      <span class="itemtext">{{
                        blinks[blinks.length - 1].title
                      }}</span>
                    </span>

                    <span
                      :class="`${
                        lag == 'en' ? 'item' : 'itemf'
                      } flex-col justify-between`"
                      @click="
                        () => {
                          changelag('');
                        }
                      "
                    >
                      <span class="itemtext">中</span>
                    </span>
                    <span
                      class="item flex-col justify-between"
                      style="margin-left: 5px"
                    >
                      <span class="itemtext">/</span>
                    </span>
                    <span
                      :class="` ${
                        lag == 'en' ? 'itemf' : 'item'
                      }  flex-col justify-between`"
                      style="margin-left: 5px"
                      @click="
                        () => {
                          changelag('en');
                        }
                      "
                    >
                      <span class="itemtext">EN</span>
                    </span>
                  </div>

                  <div
                    class="showbar"
                    @click="openBardiv"
                    v-if="barclass == 'closethis'"
                  ></div>
                </div>

                <div class="darkback" :style="{ opacity: topbarOpacity }"></div>
              </div>

              <div
                class="group_2 flex-row"
                :style="`background-image: url(${baseUrl}${
                  w750 == 1 ? basedata.tback1m : basedata.tback1
                }); 
                ${w750 == 1 ? `height:${m_group_2_height}px;` : ``}`"
              >
                <div class="text-group_1 flex-col justify-between">
                  <span class="text_7" v-html="basedata.aititle"></span>
                  <span class="text_8" v-html="basedata.aicontent"></span>
                </div>

                <div data-v-55592118="" class="btn-group_3 flex-row">
                  <div
                    data-v-55592118=""
                    class="btn-group_4 flex-row"
                    @click="
                      () => {
                        goDiv('pingtaineirong', '');
                      }
                    "
                  >
                    <div
                      data-v-55592118=""
                      class="image-text_1 flex-row justify-between"
                    >
                      <span class="text-group_2" v-if="lag == 'en'"
                        >Learn more</span
                      >
                      <span class="text-group_2" v-else>了解更多</span>
                      <img
                        class="text-label_4"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4a8438938b60f6daa91280dc137b8736f6fc4825e1a197fea27cfab5c620f1be"
                      />
                    </div>
                  </div>
                </div>

                <video
                  v-if="w750 == 0"
                  id="video0"
                  :show-center-play-btn="false"
                  :controls="false"
                  style="
                    position: absolute;
                    object-fit: contain;
                    object-position: center;
                    max-width: 100%;
                    max-height: 100%;
                    background-color: none;
                  "
                  preload="load"
                  playsinline="true"
                  webkit-playsinline="true"
                  x-webkit-airplay="allow"
                  airplay="allow"
                  x5-video-player-type="h5"
                  x5-video-player-fullscreen="true"
                  x5-video-orientation="portrait"
                  src="assets/videos/q.mp4"
                  autoplay
                  muted
                  loop
                ></video>

                <!-- <img v-if="w750 == 1" class="videoxl" :src="bimgurl" /> -->
                <div v-if="w750 == 1" class="videoxl">
                  <!-- <img v-if="w750 == 1" :src="bimgurl" /> -->
                  <canvas id="videocanvas"></canvas>
                </div>
              </div>
            </div>

            <div data-v-55592118="" class="section_7 flex-col">
              <div data-v-55592118="" class="box_1 flex-row justify-between">
                <div
                  class="pingtaineirong"
                  style="position: absolute; top: -12rem"
                ></div>

                <template v-for="(item, index) in itblist">
                  <template>
                    <div
                      data-v-55592118=""
                      :class="`image-text_box itb_0 flex-row  ${
                        itbindex == index && w750 == 0 ? 'itbanimate' : ''
                      } ${
                        itblindex == index && w750 == 0 ? 'itblanimate' : ''
                      }`"
                      :style="`background-image:url(${item.url});`"
                      @mouseover="
                        () => {
                          itbhandleHover(index);
                        }
                      "
                      @mouseleave="
                        () => {
                          itbhandleLeave(index);
                        }
                      "
                      @click="
                        () => {
                          selectclass2(item.cid);
                        }
                      "
                      :key="index"
                    >
                      <div
                        data-v-55592118=""
                        class="it_box flex-row justify-between"
                      >
                        <span data-v-55592118="" class="tb_text">{{
                          item.title
                        }}</span>
                        <img
                          v-if="itbindex != index"
                          data-v-55592118=""
                          referrerpolicy="no-referrer"
                          src="../assets/imgs/icon-linepoint.png"
                          class="tb_icon"
                        />
                        <img
                          v-if="itbindex == index"
                          data-v-55592118=""
                          referrerpolicy="no-referrer"
                          src="../assets/imgs/icon-linepointf.png"
                          class="tb_icon"
                        />
                      </div>

                      <div
                        v-if="w750 == 0"
                        :class="`itb_contentf flex-row pingtaineirong ${
                          itbindex == index ? 'itbcontentanimatef' : ''
                        } ${itblindex == index ? 'itbcontentlanimatef' : ''}`"
                        :key="`i${index}`"
                      >
                        <div class="itb_content_inf">{{ item.content }}</div>
                      </div>
                    </div>

                    <div
                      v-if="w750 == 1"
                      :class="`itb_content flex-row pingtaineirong ${
                        itbindex == index ? 'itbcontentanimate' : ''
                      } ${itblindex == index ? 'itbcontentlanimate' : ''}`"
                      :key="`i${index}`"
                    >
                      <div class="itb_content_in">{{ item.content }}</div>
                    </div>
                  </template>
                </template>
              </div>

              <div
                data-v-55592118=""
                class="historyline box_2 flex-col"
                :style="w750 == 0 ? `height:${m_group_2_height}px` : ``"
              >
                <div
                  class="fazhanlicheng"
                  style="position: absolute; top: -12rem"
                ></div>
                <div data-v-55592118="" class="text-wrapper_9 flex-row">
                  <span
                    data-v-55592118=""
                    class="hl_text_21"
                    style="text-transform: uppercase"
                    >{{ basedata.t7 }}</span
                  >
                </div>

                <div class="box_2_img flex-row">
                  <img
                    v-if="w750 == 1 && lag == ''"
                    src="assets/textures/fzs.png"
                    style="width: 100%; height: auto"
                  />
                  <img
                    v-if="w750 == 1 && lag == 'en'"
                    src="assets/textures/enfzs.png"
                    style="width: 100%; height: auto"
                  />

                  <img
                    v-if="w750 == 0 && lag == ''"
                    src="assets/textures/fzl.png"
                    style="width: 100%; height: auto"
                  />
                  <img
                    v-if="w750 == 0 && lag == 'en'"
                    src="assets/textures/enfzl.png"
                    style="width: 100%; height: auto"
                  />
                </div>

                <!-- <div
                  data-v-55592118=""
                  class="box_3 flex-row"
                  :style="`zoom:${box2zoom};`"
                >
                  <img src="../assets/imgs/fzs.png" style="width:100%; height:auto;" />
                  <div data-v-55592118="" class="image-wrapper_5 flex-col">
                    <img
                      data-v-55592118=""
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc57f8ee35b9a33860b6e8f29a8befa09c76941e77acd19a294ee7db13d0cbce0"
                      class="image_29"
                    /><img
                      data-v-55592118=""
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdfc00d6acfa956430c7b99f9c5e65eab15aed23e8e7cb71d1f42fafd70c0a3c2"
                      class="image_30"
                    /><img
                      data-v-55592118=""
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1e215e2fcaaba82b7ba1927f194e19ba5ee2a67106587debd67cc236aac86be2"
                      class="image_31"
                    /><img
                      data-v-55592118=""
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge6ad419de7f5b946baacc159c8073ac80b0391d3267c185082b0faaf243cf20f"
                      class="image_32"
                    /><img
                      data-v-55592118=""
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfc9958a10579d3d9bf60e7b920f4cd8ba75a7e8e375953167125b405128a206b"
                      class="image_33"
                    /><img
                      data-v-55592118=""
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf47d85340de0aa58dbcbbb05ab6e5fd7ee723886deb622b4721baf1913ddd6f7"
                      class="image_34"
                    />
                  </div>
                  <div
                    data-v-55592118=""
                    class="text-group_9 flex-col justify-between"
                  >
                    <span data-v-55592118="" class="hl_text_22"
                      >会务管理系统</span
                    ><span data-v-55592118="" class="hl_text_23"
                      >会务管理系统1.0</span
                    >
                  </div>
                  <img
                    data-v-55592118=""
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng958f828c62b1436ef10986fd8bd9ca5967c30252d5589af71de1b71370b9c4ee"
                    class="image_35"
                  />
                  <div
                    data-v-55592118=""
                    class="text-group_10 flex-col justify-between"
                  >
                    <span data-v-55592118="" class="hl_text_24">云展平台</span
                    ><span data-v-55592118="" class="hl_text_25"
                      >AI家园&amp;“造”字云展平台</span
                    >
                  </div>
                  <div
                    data-v-55592118=""
                    class="text-group_11 flex-col justify-between"
                  >
                    <span data-v-55592118="" class="hl_text_26">大会网站</span
                    ><span data-v-55592118="" class="hl_text_27">WAIC网站</span>
                  </div>
                  <div
                    data-v-55592118=""
                    class="text-group_12 flex-col justify-between"
                  >
                    <span data-v-55592118="" class="hl_text_28"
                      >会务管理系统</span
                    ><span data-v-55592118="" class="hl_text_29"
                      >招商招展&amp;购票注册系统</span
                    >
                  </div>
                  <img
                    data-v-55592118=""
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge7d2579acf3fd6b88a2b911aaa904a27450eb2f473beaf1a0c4edd30294596c9"
                    class="image_36"
                  />
                  <div
                    data-v-55592118=""
                    class="text-group_13 flex-col justify-between"
                  >
                    <span data-v-55592118="" class="hl_text_30">云展平台</span
                    ><span data-v-55592118="" class="hl_paragraph_2"
                      >云平台2.0<br data-v-55592118="" />工博会在线</span
                    >
                  </div>
                  <div
                    data-v-55592118=""
                    class="text-group_14 flex-col justify-between"
                  >
                    <span data-v-55592118="" class="hl_text_31">大会网站</span
                    ><span data-v-55592118="" class="hl_text_32">WAIC网站</span>
                  </div>
                  <div
                    data-v-55592118=""
                    class="text-group_15 flex-col justify-between"
                  >
                    <span data-v-55592118="" class="hl_text_33">元宇宙平台</span
                    ><span data-v-55592118="" class="hl_text_34">元境星球</span>
                  </div>
                  <img
                    data-v-55592118=""
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng612295db60d164e8f3bfb349bc71742f3097d255610bcff05d5e20129cf3d29e"
                    class="image_37"
                  />
                  <div
                    data-v-55592118=""
                    class="text-group_16 flex-col justify-between"
                  >
                    <span data-v-55592118="" class="hl_text_35"
                      >数字会务管理系统</span
                    ><span data-v-55592118="" class="hl_text_36"
                      >数字会务系统（AIGC+SaaS）</span
                    >
                  </div>
                  <img
                    data-v-55592118=""
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng93bb8e8e198d8d24c4cda727943de1498068b4132fcda4d8209f9b075023373f"
                    class="image_38"
                  />
                  <div
                    data-v-55592118=""
                    class="text-group_17 flex-col justify-between"
                  >
                    <span data-v-55592118="" class="hl_text_37"
                      >智慧云展平台</span
                    ><span data-v-55592118="" class="paragraph_3"
                      >智慧云平台（大数据推荐）<br
                        data-v-55592118=""
                      />元宇宙平台</span
                    >
                  </div>
                  <span data-v-55592118="" class="hl_text_38"
                    >大数据分析平台</span
                  ><span data-v-55592118="" class="hl_text_39"
                    >大数据分析平台（大数据管理、智能分析）</span
                  ><img
                    data-v-55592118=""
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc04b661ec1706e6283dbdb5768b67e34668a42a002a98a14b2f7e9618802b706"
                    class="image_39"
                  /><span data-v-55592118="" class="hl_text_40"
                    >AIGC应用平台</span
                  ><span data-v-55592118="" class="hl_text_41"
                    >AIGC应用产品</span
                  ><span data-v-55592118="" class="hl_text_42">2023</span
                  ><img
                    data-v-55592118=""
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga5e22a79d805e6278c2285083631d116ce41cae87abd918af8d4cec2bf16a519"
                    class="image_40"
                  />
                  <div data-v-55592118="" class="text-wrapper_10 flex-col">
                    <span data-v-55592118="" class="hl_text_43">2020</span>
                  </div>
                  <div data-v-55592118="" class="text-wrapper_11 flex-col">
                    <span data-v-55592118="" class="hl_text_44">2021</span>
                  </div>
                  <div data-v-55592118="" class="text-wrapper_12 flex-col">
                    <span data-v-55592118="" class="hl_text_45">2022</span>
                  </div>
                </div> -->
              </div>
            </div>

            <span class="text_51" style="text-transform: uppercase">
              <div class="anli" style="position: absolute; top: -12rem"></div>
              {{ basedata.t8 }}
            </span>
            <div class="group_10 flex-row justify-between">
              <div class="mkdiv flex-row justify-between">
                <a class="mkitema">
                  <div
                    :class="`mkitem ${
                      thisclass2 == '' ? 'selected' : ''
                    } flex-col`"
                    style="padding-left: 3rem; padding-right: 3rem"
                    @click="
                      () => {
                        selectclass2('');
                      }
                    "
                  >
                    <span class="mktext" v-if="lag == 'en'">ALL</span>
                    <span class="mktext" v-else>全部</span>
                  </div>
                </a>

                <template v-for="(item, index) in mkinds">
                  <a class="mkitema" :key="index">
                    <div
                      :class="`mkitem flex-col  ${
                        thisclass2 == item.id ? 'selected' : ''
                      }`"
                      @click="
                        () => {
                          selectclass2(item.id);
                        }
                      "
                    >
                      <span class="mktext" v-html="item.name"></span>
                    </div>
                  </a>
                </template>
              </div>
              <!-- <img
                class="image_21"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngeda3e001b1ab1f2ff8ccdbb40d2fa36bc422f9eaebe86adbeab1f91224c04650"
              /> -->

              <div class="images_more_list" v-if="w750 == 0">
                <div class="imlitem imli0"></div>
                <div class="imlitem imli1"></div>
                <div class="imlitem imli2"></div>
                <div class="imlitem imli3"></div>
              </div>
            </div>

            <div class="pplist flex-row justify-between">
              <svg
                v-if="dataloading"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="8rem"
                height="10rem"
                style="
                  width: 8rem;
                  height: 10rem;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  margin-top: -5rem;
                  margin-left: -4rem;
                  enable-background: new 0 0 50 50;
                "
                viewBox="0 0 24 30"
                xml:space="preserve"
              >
                <rect x="0" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
                <rect x="10" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
                <rect x="20" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </svg>
              <template v-if="data.length > 0">
                <template v-for="(item, index) in data">
                  <div
                    :class="`ppitem ${ppindex == index ? 'ppselected' : ''}`"
                    :style="`animation-delay: ${index * 0.1}s;`"
                    :key="index"
                    @mouseover="
                      () => {
                        pphandleHover(index);
                      }
                    "
                    @touchstart="
                      () => {
                        pphandleHover(index);
                      }
                    "
                    @mouseleave="pphandleLeave"
                  >
                    <!-- <img
                      :class="`ppimg ${ppindex == index ? 'ppimgshow' : ''}`"
                      referrerpolicy="no-referrer"
                      :src="`${baseUrl + item.imgary[0].url}`"
                    /> -->
                    <img
                      :class="`ppimg`"
                      referrerpolicy="no-referrer"
                      :src="`${baseUrl + item.imgary[0].url}`"
                    />

                    <!-- <div
                      class="block_6 flex-col"
                      v-if="ppindex == index"
                      @click="
                        () => {
                          if (item.url == '') {
                            swSlideTo(item.swiperindex, item.id);
                          } else {
                            gowebsite(item.url);
                          }
                        }
                      "
                    > -->

                    <div
                      class="block_6 flex-col"
                      @click="
                        () => {
                          if (item.url == '') {
                            swSlideTo(item.swiperindex, item.id);
                          } else {
                            gowebsite(item.url);
                          }
                        }
                      "
                    >
                      <div class="ppinfo" style="margin-bottom: 0rem">
                        <!-- <span
                          class="text_57"
                          v-if="lag == 'en' && item.enpname != ''"
                          v-html="item.enpname"
                        ></span>
                        <span class="text_57" v-else v-html="item.pname"></span>
                         -->

                        <span
                          class="text_57_240227"
                          v-if="lag == 'en' && item.enpname != ''"
                          v-html="item.enpname"
                          style="color: #fff"
                        ></span>
                        <span
                          class="text_57_240227"
                          v-else
                          v-html="item.pname"
                          style="color: #fff"
                        ></span>

                        <span
                          class="des_57_240227"
                          v-if="lag == 'en' && item.endes != ''"
                          v-html="item.endes"
                          style="color: #fff"
                        ></span>
                        <span
                          class="des_57_240227"
                          v-else
                          v-html="item.des"
                          style="color: #fff"
                        ></span>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="nopplistshow">
                <div class="nopplist">- NONE -</div>
              </template>
            </div>

            <a
              class="ahover"
              :href="lag == 'en' ? `#/plist/tech/en` : `#/plist/tech`"
            >
              <!-- <a class="ahover" href="#/plist/ai"> -->
              <div class="ppmore flex-col">
                <span class="text" v-if="lag == 'en'">MORE</span>
                <span class="text" v-else>更多案例</span>
              </div>
            </a>

            <span
              class="text_12 text_51"
              style="text-transform: uppercase; margin-top: 4rem"
            >
              <div
                class="huoban"
                style="position: absolute; margin-top: -12rem"
              ></div>
              {{ basedata.t11 }}
            </span>

            <div class="hblist flex-col aiflist" style="margin-bottom: 7rem">
              <div class="swiper-container sc2">
                <div class="swiper-wrapper">
                  <template v-for="(item, index) in frienddata2">
                    <div class="swiper-slide ss2" :key="index">
                      <template v-for="(item2, index2) in item">
                        <a class="limga" :key="index2">
                          <div
                            class="litem"
                            @click="windowopen(item2.url)"
                            style="
                              border: 1px solid rgba(225, 225, 225, 1);
                              margin-bottom: 0.1rem;
                            "
                          >
                            <img
                              class="limg"
                              style="
                                -webkit-filter: grayscale(0%);
                                filter: grayscale(0%);
                              "
                              referrerpolicy="no-referrer"
                              :src="baseUrl + item2.imgurl"
                            />
                          </div>
                        </a>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <footer-index :w750="w750" :basedata="basedata" :lag="lag" />
          </div>

          <div class="showppdiv">
            <div class="closesw"></div>
            <div class="swiper-container sc">
              <div class="swiper-wrapper">
                <template v-for="(item, index) in data">
                  <div
                    class="swiper-slide ss0"
                    v-if="item.url == ''"
                    :key="index"
                  >
                    <div class="ss ss0">
                      <div class="ssin">
                        <div class="scontent">
                          <!-- <div class="sstitle" v-html="item.pname"></div> -->
                          <div
                            class="sstitle"
                            v-if="lag == 'en' && item.enpname != ''"
                            v-html="item.enpname"
                          ></div>
                          <div class="sstitle" v-else v-html="item.pname"></div>

                          <!-- <div class="ppkinds flex-row justify-start">
                            <template
                              v-for="(item2, index2) in item.keywords.split(
                                ','
                              )"
                            >
                              <div class="ppkitem flex-col" :key="index2">
                                <span class="ppktext" v-html="item2"></span>
                              </div>
                            </template>
                          </div> -->
                          <div
                            class="ppkinds flex-row justify-start"
                            v-if="lag == 'en' && item.enkeywords != ''"
                          >
                            <template
                              v-for="(item2, index2) in item.enkeywords.split(
                                ','
                              )"
                            >
                              <div class="ppkitem flex-col" :key="index2">
                                <span class="ppktext" v-html="item2"></span>
                              </div>
                            </template>
                          </div>
                          <div class="ppkinds flex-row justify-start" v-else>
                            <template
                              v-for="(item2, index2) in item.keywords.split(
                                ','
                              )"
                            >
                              <div class="ppkitem flex-col" :key="index2">
                                <span class="ppktext" v-html="item2"></span>
                              </div>
                            </template>
                          </div>

                          <!-- <div class="ppcontent" v-html="item.content"></div> -->
                          <div
                            class="ppcontent"
                            v-if="lag == 'en' && item.encontent != ''"
                          >
                            <div
                              v-if="item.swiperindex == selectIndex"
                              v-html="item.encontent"
                            ></div>
                          </div>
                          <div class="ppcontent" v-else>
                            <div
                              v-if="item.swiperindex == selectIndex"
                              v-html="item.content"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="swiper-pagination"></div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>

          <!-- <a-layout-footer class="layfoot">
            <footer-bar />
          </a-layout-footer> -->
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { fetchClass, fetchCase, fetchBase, fetchFriend } from "../api/index";
import Swiper from "swiper";
import FooterIndex from "../components/FooterIndex.vue";
import "swiper/css/swiper.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "ai",
  components: {
    FooterIndex,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      lag: "",
      blstarted: false,
      bimgurl: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 69,
      thisclass2: "",

      classlist: [],

      class2list: [],
      frienddata2: [],

      box2zoom: 1,

      swiper: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      gtitle: "整合内容营销&nbsp;+&nbsp;智能数据平台&nbsp;+&nbsp;奢侈品营销",
      gdesc:
        "IPmotion（上海派志）作为一类以独特创意DNA驱动的公司，而区别于一般的“广告创意公司”。<br />成立十多年来，IPmotion（上海派志）在人工智能、IT电子、医药、汽车等垂直领域积累了丰富的经验案例，并助力企业、品牌的营销转型和数字转型的全过程。为全球500强与国内行业领先品牌，如：智能科技类企业：世界人工智能大会、世界制造业大会、华为、远景集团、医药类企业：阿斯利康、基石药业、BD医疗、奢侈品类企业：香奈儿、MHD、帕玛强尼等提供全案创意营销代理服务和线下解决方案。",
      mkinds: [
        "展览展厅&活动",
        "数字营销&视频创作",
        "智能平台&多媒体互动",
        "奢侈品营销",
      ],
      ppindex: -1,
      pplist: [],
      bselectindex: -1,
      blinks: [
        // { title: "公司简介", url: "", class: "jianjie" },
        { title: "", url: "", class: "pingtaineirong" },
        { title: "", url: "", class: "fazhanlicheng" },
        { title: "", url: "", class: "anli" },
        { title: "", url: "", class: "lianxiwomen" },
        { title: "", url: "index", class: "" },
      ],

      itbindex: -1,
      itblindex: -1,
      itblist: [
        {
          title: "会务系统",
          content:
            "利用生成式AI+模块化产品，实现对会务管理系统的模块选择、快速搭建和智能管理，复用到各大展会，用于提高大会效率和质量。",
          url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f39e6bd8401448678fcc9663c93ccf83_mergeImage.png",
          cid: 81,
        },
        {
          title: "数字分析",
          content:
            "利用生成式AI+模块化产品，实现对会务管理系统的模块选择、快速搭建和智能管理，复用到各大展会，用于提高大会效率和质量。",
          url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/c9928e6c655d4597bda87a01169f22d4_mergeImage.png",
          cid: 82,
        },
        {
          title: "智慧云展",
          content:
            "利用生成式AI+模块化产品，实现对会务管理系统的模块选择、快速搭建和智能管理，复用到各大展会，用于提高大会效率和质量。",
          url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d5eccdd25f0144e489b9ad10e2afcfd9_mergeImage.png",
          cid: 83,
        },
        {
          title: "数字孪生",
          content:
            "利用生成式AI+模块化产品，实现对会务管理系统的模块选择、快速搭建和智能管理，复用到各大展会，用于提高大会效率和质量。",
          url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d57c4330d8ed46f988d1ec4a2f354163_mergeImage.png",
          cid: 84,
        },
        {
          title: "AIGC",
          content:
            "利用生成式AI+模块化产品，实现对会务管理系统的模块选择、快速搭建和智能管理，复用到各大展会，用于提高大会效率和质量。",
          url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e734701200394e748ea321617babc72c_mergeImage.png",
          cid: 85,
        },
      ],

      sectionindex: 0,
      sections: [
        {
          img: "i-logo-0.png",
          title: "展览展厅&amp;活动",
          desc: "提供前沿的创意理念，及线下制作的一体化服务",
          tip: ["临时展厅", "常设展厅", "多媒体互动", "交互内容", "线下活动"],
        },
        {
          img: "i-logo-1.png",
          title: "智能平台&amp;多媒体互动",
          desc: "提供服务与解决方案的智能科技应用平台",
          tip: ["智能会务系统", "智能云展", "数字孪生", "智能机器人"],
        },
        {
          img: "i-logo-2.png",
          title: "智能平台&amp;多媒体互动",
          desc: "提供服务与解决方案的智能科技应用平台",
          tip: ["智能会务系统", "智能云展", "数字孪生", "智能机器人"],
        },
        {
          img: "i-logo-3.png",
          title: "奢侈品营销",
          desc: "结合线下制作、运营于一体的互动体验",
          tip: ["促销活动", "体验营销"],
        },
      ],
      data: [],
      dataloading: 0,
      pageSize: 6,
      query: {
        class1: 69,
        class2: [],
      },
      alive: true,
    };
  },
  deactivated() {
    console.log("deactivated:");
    this.blstarted = false;
    this.alive = false;
  },
  activated() {
    let that = this;
    console.log("onActivated");
    let scrollvalue = global.getIndexscroll();
    if (scrollvalue > 0) {
      $(".main").scrollTop(scrollvalue);
    }

    if (scrollvalue > 400) {
      this.topbarOpacity = (scrollvalue - 400) / 200;
      if (this.topbarOpacity > 1) {
        this.topbarOpacity = 1;
      }
    } else {
      this.topbarOpacity = 0;
    }

    that.resizepage();

    setTimeout(() => {
      let classtext = global.getIndexDclass();
      console.log("classtext:", classtext);
      if (classtext != "") {
        that.goDiv(classtext, "");
      }
    }, 1000);
    this.alive = true;
  },
  mounted() {
    let that = this;

    let lag = this.$route.params.lag;

    if (lag) {
      this.lag = lag;
    }

    global.setIndexDclass("");
    global.setIndexscroll(0);
    this.handleScroll;
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("resize", this.handleResize, true);
    this.myswiper();
    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        let videos = $(".showppdiv").find("video");

        for (var i = 0; i < videos.length; i++) {
          console.log("video:", videos[i]);
          videos[i].pause();
        }

        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
    }, 1000);

    setTimeout(() => {
      if (this.w750 == 0) {
        var video = document.getElementById("video0");
        video.play();
      }
    }, 1000);
    that.getClassList();
    that.fetch();
    that.fetchb();
    that.fetchf();
  },
  beforeCreate() {
    let that = this;
  },
  methods: {
    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },
    fetchf(params = { kind: "10", show: 1, pagesize: 1000 }) {
      let that = this;
      this.loading = true;

      fetchFriend(params).then((res) => {
        // console.log("fetchFriend:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        that.frienddata = res.results.list;

        let frienddata2 = [];

        let lindex = 0;
        // res.results.list.map((item, index) => {
        //   if (index % 4 == 0) {
        //     frienddata2[lindex] = [];
        //   }
        //   frienddata2[lindex].push(item);
        //   if (index % 4 == 3) {
        //     lindex++;
        //   }
        // });
        res.results.list.map((item, index) => {
          frienddata2[index] = [];
          frienddata2[index].push(item);
        });
        that.frienddata2 = frienddata2;

        // console.log("frienddata:", that.frienddata);
        console.log("frienddata2:", that.frienddata2);

        if (that.w750 == 0) {
          that.myswiper2();
        } else {
          that.myswiper2m();
        }
      });
    },
    fetch(
      // params = { show: 1, hot: 1, pagesize: this.pageSize, query: this.query }
      params = { show: 1, pagesize: this.pageSize, query: this.query }
    ) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchCase(params).then((res) => {
        console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
        console.log("data:", that.data);
        setTimeout(() => {
          that.swiper.update();
        }, 100);
      });
    },

    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        console.log("fetchBase:", res.results);
        that.loading = false;
        that.basedata = res.results;

        that.blinks[0].title = res.results.t6;
        that.blinks[1].title = res.results.t7;
        that.blinks[2].title = res.results.t8;
        that.blinks[3].title = res.results.t9;
        that.blinks[4].title = res.results.t10;

        that.itblist[0].title = that.basedata["aititle0"];
        that.itblist[1].title = that.basedata["aititle1"];
        that.itblist[2].title = that.basedata["aititle2"];
        that.itblist[3].title = that.basedata["aititle3"];
        that.itblist[4].title = that.basedata["aititle4"];

        that.itblist[0].content = that.basedata["aicontent0"];
        that.itblist[1].content = that.basedata["aicontent1"];
        that.itblist[2].content = that.basedata["aicontent2"];
        that.itblist[3].content = that.basedata["aicontent3"];
        that.itblist[4].content = that.basedata["aicontent4"];

        if (that.lag == "en") {
          that.blinks[0].title = res.results.ent6;
          that.blinks[1].title = res.results.ent7;
          that.blinks[2].title = res.results.ent8;
          that.blinks[3].title = res.results.ent9;
          that.blinks[4].title = res.results.ent10;
          // that.blinks[6].title = res.results.ent6;
          // that.blinks[7].title = res.results.ent7;
          // that.blinks[8].title = res.results.ent8;
          // that.blinks[9].title = res.results.ent9;
          // that.blinks[10].title = res.results.ent10;

          that.basedata.t0 = res.results.ent0;
          that.basedata.t1 = res.results.ent1;
          that.basedata.t2 = res.results.ent2;
          that.basedata.t3 = res.results.ent3;
          that.basedata.t4 = res.results.ent4;
          that.basedata.t5 = res.results.ent5;
          that.basedata.t6 = res.results.ent6;
          that.basedata.t7 = res.results.ent7;
          that.basedata.t8 = res.results.ent8;
          that.basedata.t9 = res.results.ent9;
          that.basedata.t10 = res.results.ent10;
          that.basedata.t11 = res.results.ent11;

          that.basedata.tel = res.results.entel;
          that.basedata.adr = res.results.enadr;
          that.basedata.email = res.results.enemail;
          that.basedata.mtitle = res.results.enmtitle;
          that.basedata.mcontent = res.results.enmcontent;
          that.basedata.aititle = res.results.enaititle;
          that.basedata.aicontent = res.results.enaicontent;
          that.basedata.aicontent0 = res.results.enaicontent0;
          that.basedata.aicontent1 = res.results.enaicontent1;
          that.basedata.aicontent2 = res.results.enaicontent2;
          that.basedata.aicontent3 = res.results.enaicontent3;
          that.basedata.aicontent4 = res.results.enaicontent4;

          that.itblist[0].title = that.basedata["enaititle0"];
          that.itblist[1].title = that.basedata["enaititle1"];
          that.itblist[2].title = that.basedata["enaititle2"];
          that.itblist[3].title = that.basedata["enaititle3"];
          that.itblist[4].title = that.basedata["enaititle4"];

          that.itblist[0].content = that.basedata["enaicontent0"];
          that.itblist[1].content = that.basedata["enaicontent1"];
          that.itblist[2].content = that.basedata["enaicontent2"];
          that.itblist[3].content = that.basedata["enaicontent3"];
          that.itblist[4].content = that.basedata["enaicontent4"];
        }
      });
    },

    selectclass2(id) {
      this.nopplistshow = 0;
      this.dataloading = 1;
      this.thisclass2 = id;
      console.log(id);
      this.query = {
        class1: this.thisclass1,
        class2: [id],
      };
      this.data = [];

      this.goDiv("anli", "");

      setTimeout(() => {
        this.fetch();
      }, 500);
    },
    getClassList() {
      const that = this;
      fetchClass().then((res) => {
        console.log(
          "%cfetchClass",
          "color: green;font-size: 14px;font-weight: bold;",
          res
        );
        that.classlist = res.results.list;

        // console.log("classlist:", that.classlist);

        that.class2list = this.classlist.filter((it) => {
          return this.thisclass1 == it.id;
        })[0]["list"];

        // console.log("class2list:", that.class2list);

        let mkinds = [];

        that.class2list.map((item) => {
          let cname = item.cname;
          if (that.lag == "en") {
            cname = item.encname;
          }
          mkinds.push({ name: cname, id: item.id });
        });

        that.mkinds = mkinds;

        console.log("mkinds:", this.mkinds);
      });
    },

    itbhandleLeave(index) {
      this.itbindex = -1;
      this.itblindex = index;
    },
    itbhandleHover(index) {
      setTimeout(() => {
        if (this.itbindex == this.itblindex) this.itblindex = -1;
      }, 300);

      this.itbindex = index;
    },
    swSlideTo(index, id) {
      let that = this;
      if (this.w750 == 0) {
      } else if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/pdetall/${d}/en`);
        } else {
          this.$router.push(`/pdetall/${id}`);
        }

        return false;
      }

      that.swiper.slideTo(index, 0);
      $(".showppdiv").css({ left: "0%" });
      $(".showppdiv").fadeTo(300, 1, () => {});
    },

    gowebsite(url) {
      window.open(url);
    },

    myswiper() {
      let that = this;

      setTimeout(() => {
        this.swiper = new Swiper(".sc", {
          pagination: {
            // el: '.swiper-pagination',
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          on: {
            transitionEnd: function () {
              that.selectIndex = this.activeIndex;
              console.log(that.selectIndex);
            },
            init: function (swiper) {
              this.emit("transitionEnd"); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
            },
          },
        });
      }, 10);
    },

    myswiper2() {
      let that = this;

      setTimeout(() => {
        this.swiper2 = new Swiper(".sc2", {
          speed: 2000,
          slidesPerView: 5,
          slidesPerColumn: 1,
          spaceBetween: 0,
          freeMode: true,
          loop: true,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      }, 10);
    },

    myswiper2m() {
      let that = this;

      setTimeout(() => {
        this.swiper2 = new Swiper(".sc2", {
          speed: 2000,
          slidesPerView: 2,
          slidesPerColumn: 1,
          spaceBetween: 0,
          // freeMode: true,
          // loop: true,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      }, 10);
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },
    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;
      if (window.innerWidth > 1920) {
        this.pageWidth = 1920;
      }
      this.m_group_2_height = pageHeight;
      if (window.innerWidth <= 750) {
        this.w750 = 1;
      } else {
        this.w750 = 0;
      }
      console.log("resize", this.pageWidth);
      // setTimeout(() => {
      $("html").css({ fontSize: this.pageWidth / 192 });

      this.box2zoom = this.pageWidth / 1920;
      // }, 500);

      // setTimeout(() => {
      //   that.resizepage();
      // },100);

      if (this.w750 == 1) {
        if (!this.blstarted) {
          this.blstarted = true;
          this.bxuliestart(0);
        }
      } else {
        this.blstarted = false;
      }
    },
    bxuliestart(index) {
      if (this.w750 == 1 && this.blstarted) {
        let that = this;

        let image = new Image();
        image.src = "assets/textures/blueball/b" + index + ".png";

        image.onload = function () {
          that.bimgurl = image.src;

          // var canvas = document.createElement("canvas");

          var canvas = document.getElementById("videocanvas");

          canvas.width = 500;

          canvas.height = 500;

          var context = canvas.getContext("2d");

          context.drawImage(this, 0, 0, 500, 500);

          // that.videoimg = image
          setTimeout(() => {
            index++;
            if (index > 143) {
              index = 0;
            }
            that.bxuliestart(index);
          }, 100);
        };
      }
    },
    handleScroll(e) {
      // console.log("handleScroll");
      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        // console.log("winHeight:", winHeight);
        global.setIndexscroll(winHeight);
        if (winHeight > 400) {
          this.topbarOpacity = (winHeight - 400) / 200;
          if (this.topbarOpacity > 1) {
            this.topbarOpacity = 1;
          }
        } else {
          this.topbarOpacity = 0;
        }
      }
    },

    changelag(lag) {
      if (lag == "en") {
        if (this.lag != "en") {
          this.$router.push("tech/en");
          setTimeout(() => {
            location.reload();
          }, 100);
        }
      } else {
        if (this.lag == "en") {
          this.$router.push("/tech");
          setTimeout(() => {
            location.reload();
          }, 100);
        }
      }
    },

    goDiv(classtext, url) {
      this.barclass = "closethis";

      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      // 获取元素在页面中的位置
      // console.log(this.$refs.dingyue_box_ref.getBoundingClientRect());

      // document
      //   .getElementById("agentTitle")
      //   .scrollIntoView({ block: "start", behavior: "smooth" });
      // 跳转到指定位置并且平滑滚动
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    blinkshandleHover(index) {
      this.bselectindex = index;
      console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/index.css" />
<style src="../assets/css/indexai.css" />
<style src="../assets/css/index1280.css" />
<style src="../assets/css/index750.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#video0 {
  position: absolute;
  object-fit: contain;
  object-position: center center;
  max-width: 100%;
  max-height: 100%;
  left: 100rem;
  top: 20rem;
  width: 70rem;
  height: 70rem;
  border-radius: 35rem;
  background-color: none;
}
</style>
